.body{
	font-family: $font-family-base;
}
.nav-link.active {
    color: #000 !important;
}
a.nav-link:hover, a.nav-link:focus {
    color: #000 !important;
}
//-----------------------------------------------------------------
// Font size
//
$font-size-list: 8 9 10 11 12 13 14 15 16 17 18 19 20 22 24 25 26 28 30 35 40 45 50 60 70 80 90;
@each $value in $font-size-list {
  .fs-#{$value} {
    font-size: #{$value}px !important;

    @if $value > 29 {
      line-height:1.2;
    }
  }
}


.header{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}


.howto{
	height: 245px;
	h5{
		height: 58px;
	}
	.howto__des {
		display: none;
	}
	&:hover{
		.howto__des {
			display: block;
		}
	}
}

// .index-3{
// 	z-index: 1010;
// }

// .index-2{
// 	z-index: 1011;
// }

// .index-1{
// 	z-index: 1012;
// }

@media only screen and (min-width: 768px) {
	.overlay{
		opacity: 1 !important;
	}
}


// .howto{
// 	perspective: 1000;
// 	transform-style: preserve-3d;
// 	transition: all 1.0s linear;
// 	&:hover {
// 	  	transform: rotateY(180deg);
// 	  	box-shadow: -5px 5px 5px #aaa;
// 	}
// }

// .howto__des{
// 	display: none;
// }

// .howto__des {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   backface-visibility: hidden;
// }
// .howto__des {
//   display: block;
//   transform: rotateY(180deg);
//   box-sizing: border-box;
//   padding: 10px;
//   color: white;
//   text-align: center;
//   background-color: #aaa;
// }

// .howto__des{
// 	position: relative;
// 	p{
// 		opacity: 0;
// 	    top: 0;
// 	    right: 0;
// 	    left: 0;
// 	    position: absolute;
// 	    transition-duration: .25s;
// 	    transition-property: background-color,opacity;
// 	    visibility: hidden;
// 	}
// }
// .howto{
// 	.card{
// 		border-radius: 0px !important; 
// 	}
// }
// .howto:hover{
// 	.howto__des{
// 		p{
// 			opacity: 1;
// 	    	visibility: visible;
// 		}
// 	}
// }